export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function formatDate(date: string | Date): string {
  if (isString(date)) {
    // return new Date(`${date}T00:00:00Z`).toLocaleDateString('en-US', {
    //   day: 'numeric',
    //   month: 'long',
    //   year: 'numeric',
    //   timeZone: 'UTC',
    // });

    return new Date(Date.parse(date)).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC',
    });
  }

  return date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
  });
}
